import AppCard from '@wieldy/components/AppCard';
import {Button} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType.js';
import About from 'app/shared/resource/Summary/About';
import BillingRates from 'app/shared/resource/Summary/BillingRates';
import Overview from 'app/shared/resource/Summary/Overview';
import WorkEnvironment from 'app/shared/resource/Summary/WorkEnvironment';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const Summary = ({resource, isCandidate, isApplicant}) => {
  const {isSupplier} = useAccountType();
  const navigate = useNavigate();
  const handleEdit = () => {
    if (isApplicant) {
      navigate(`/applicants/${resource.id}/edit-applicant`);
      return;
    }

    navigate(`/resources/${resource.id}/edit-resource`);
  };
  return (
    <AppCard
      title='Summary'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <Overview resource={resource} />
      {!!resource?.about && <About text={resource.about} />}
      <BillingRates resource={resource} />
      {!isApplicant && <WorkEnvironment resource={resource} />}
      {isSupplier && !isCandidate && (
        <Button className='mt-2' onClick={handleEdit} type='primary'>
          {isApplicant ? 'Edit Applicant' : 'Edit Resource'}
        </Button>
      )}
    </AppCard>
  );
};

Summary.propTypes = {
  resource: PropTypes.object,
  isCandidate: PropTypes.bool,
  isApplicant: PropTypes.bool,
};

Summary.defaultProps = {
  isCandidate: false,
};

export default Summary;
