import ApplicantHelper from '../../../utils/ApplicantHelper';
import ResourceHelper from '../../../utils/ResourceHelper';

export default {
  title: {
    label: 'Title',
    filter: (value) => {
      return {
        query: {
          title: value,
        },
        label: value,
      };
    },
  },
  country: {
    label: 'Location',
    options: ResourceHelper.countries(),
  },
  billing_rate: {
    label: 'Billing Rate',
    options: ResourceHelper.billingRates(),
    hasQuery: true,
  },
  availability: {
    label: 'Availability Status',
    options: ResourceHelper.availabilityStatus(true),
  },
  available_on: {
    label: 'Available On',
    filter(value) {
      return {
        query: {
          'available_on[lte]': value,
        },
        format: value.format('MM/DD/YYYY'),
      };
    },
  },

  years_experience: {
    label: 'Minimum Experience',
    options: ResourceHelper.experience(),
    hasQuery: true,
  },
  education_degree_level: {
    label: 'Minimum Education',
    options: ResourceHelper.degreeLevel(),
    filter: (value) => {
      return {
        query: {
          min_education: value,
        },
        label: value,
      };
    },
  },
  skill_tags: {
    label: 'Skills',
    filter: (value) => {
      const labels = value.map((item) => item.label);
      const tagIds = value.map((item) => item.value);
      return {
        query: {
          skill_tags: tagIds,
        },
        label: labels,
      };
    },
  },
  type: {
    label: 'Applicant Type',
    options: ApplicantHelper.applicationType(),
  },
  visa_status: {
    label: 'Visa Status',
    options: ResourceHelper.visaStatus(),
  },
  travel_availability: {
    label: 'Travel Availability',
    options: ResourceHelper.travelAvailability(),
  },
};
