import AppSteps from '@wieldy/components/AppSteps';
import AppStep from '@wieldy/components/AppSteps/AppStep';
import AppStepTitle from '@wieldy/components/AppSteps/AppStep/AppStepTitle';
import React from 'react';

import useStep from '../../../../hooks/useStep';
import EmploymentStep from '../../resources/EditResource/MainContent/ResourceSteps/Employment';
import BillingAndEnvironment from './ApplicantSteps/BillingAndEnvironment';
import SkillsStep from './ApplicantSteps/Skills';
import SubmitResourceForm from './ApplicantSteps/Submit';
import SummaryStep from './ApplicantSteps/Summary';
import SupplementalInfo from './ApplicantSteps/SupplementalInfo';

const ApplicantStepper = () => {
  const {currentStep, goToStep} = useStep();
  return (
    <AppSteps current={currentStep}>
      <AppStep
        title={
          <AppStepTitle
            title='Summary'
            isEdit={currentStep > 1}
            onEditClick={() => goToStep(1)}
          />
        }
        step={1}
        description={<SummaryStep />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Billing Rates'
            isEdit={currentStep > 2}
            onEditClick={() => goToStep(2)}
          />
        }
        step={2}
        description={<BillingAndEnvironment />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Employment History'
            isEdit={currentStep > 3}
            onEditClick={() => goToStep(3)}
          />
        }
        step={3}
        description={<EmploymentStep />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Skills'
            isEdit={currentStep > 4}
            onEditClick={() => goToStep(4)}
          />
        }
        step={4}
        description={<SkillsStep />}
      />
      <AppStep
        title={
          <AppStepTitle
            title='Supplemental Information'
            isEdit={currentStep > 5}
            onEditClick={() => goToStep(5)}
          />
        }
        step={5}
        description={<SupplementalInfo />}
      />
      <AppStep title='Submit' step={6} description={<SubmitResourceForm />} />
    </AppSteps>
  );
};

export default ApplicantStepper;
