import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Radio} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  RECRUITMENT_FEE_STRUCTURE_KEYS,
} from '../../../../constants';
import {getOptionsFromObject} from '../../../../utils/helpers/GetOptionsFromObject';
import {allowPositiveExactNumber} from '../../../../utils/helpers/preventNegativeNumber';

const radioOptions = [
  {
    label: 'Percentage of salary',
    value: RECRUITMENT_FEE_STRUCTURE_KEYS.percentage_of_salary,
  },
  {label: 'Fixed fee', value: RECRUITMENT_FEE_STRUCTURE_KEYS.fixed_fee},
];

const feePercentageValidator = (_, value) => {
  if (value === null) return Promise.resolve();

  if (value < 10 || value > 50) {
    return Promise.reject('Percentage should be from 10% to 50%');
  }

  return Promise.resolve();
};

const feeFixedValidator =
  ({baseSalary, baseSalaryMin, baseSalaryMax}) =>
  (_, value) => {
    if (value === null) return Promise.resolve();

    if (baseSalary) {
      const minValue = (baseSalary * 10) / 100;
      const maxValue = (baseSalary * 50) / 100;

      if (value < minValue || value > maxValue) {
        return Promise.reject(`Fee should be from ${minValue} to ${maxValue}`);
      }

      return Promise.resolve();
    }

    const minRangeValue = (baseSalaryMin * 10) / 100;
    const maxRangeValue = (baseSalaryMax * 50) / 100;

    if (value < minRangeValue || value > maxRangeValue) {
      return Promise.reject(
        `Fee should be from ${minRangeValue} to ${maxRangeValue}`,
      );
    }

    return Promise.resolve();
  };

const InformationForm = ({
  initialValues: {
    base_salary,
    base_salary_min,
    base_salary_max,
    ...initialValues
  },
  onSave,
  onContinue,
}) => {
  const [form] = Form.useForm();

  const feeType = Form.useWatch('recruitment_fee_structure', {form});

  const onFormSubmit = (formData) => {
    onSave(formData);
    onContinue();
  };

  const handleChangeFeeType = React.useCallback(() => {
    form.setFieldValue('recruitment_fee', undefined);
  }, [form]);

  const isPercent = feeType === 'percentage_of_salary';

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={initialValues}>
          <AppFormBody>
            <Form.Item
              label='Recruitment Fee Structure'
              name='recruitment_fee_structure'
              rules={[
                {
                  required: true,
                },
              ]}>
              <Radio.Group
                options={radioOptions}
                onChange={handleChangeFeeType}
              />
            </Form.Item>
            <Form.Item
              name='recruitment_fee'
              style={{width: '280px'}}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Fee'),
                },
                {
                  validator: isPercent
                    ? feePercentageValidator
                    : feeFixedValidator({
                        baseSalary: base_salary,
                        baseSalaryMax: base_salary_max,
                        baseSalaryMin: base_salary_min,
                      }),
                },
              ]}>
              <AppInputNumber
                placeholder={isPercent ? 'Enter percentage' : 'Enter amount'}
                prefix={isPercent ? '' : '$'}
                addonAfter={isPercent ? '%' : 'USD'}
                type='number'
                onKeyDown={allowPositiveExactNumber}
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                style={{width: '100%'}}
                controls={false}
              />
            </Form.Item>
            <Form.Item
              style={{width: 280}}
              name='guarantee_period'
              label='Guarantee Period'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Guarantee Period'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('Guarantee Period')}
                options={getOptionsFromObject(GUARANTEE_PERIOD)}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item
              style={{width: 280}}
              name='guarantee_period_action'
              label='Guarantee Period Action'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Guarantee Period Action'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('Guarantee Period Action')}
                options={getOptionsFromObject(GUARANTEE_PERIOD_ACTION)}
                allowClear={true}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

InformationForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
};

export default InformationForm;
