import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import {
  getInputPlaceHolder,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Col, Form, Input, Select} from 'antd';
import {SkillTagDropdown} from 'app/shared/components/dropdowns';
import moment from 'moment';
import React from 'react';

import {filterConfig} from './index';

const FilterApplicants = () => {
  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };
  return (
    <React.Fragment>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={filterConfig.title.label} name='title'>
          <Input placeholder={getInputPlaceHolder('title')} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={filterConfig.country.label} name='country'>
          <Select
            mode='multiple'
            placeholder={getSelectPlaceHolder('countries')}
            options={filterConfig.country.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={filterConfig.billing_rate.label} name='billing_rate'>
          <Select
            mode='multiple'
            placeholder={getSelectPlaceHolder('range(s)')}
            options={filterConfig.billing_rate.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={filterConfig.availability.label} name='availability'>
          <Select
            placeholder={getSelectPlaceHolder('status')}
            options={filterConfig.availability.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={filterConfig.available_on.label} name='available_on'>
          <AppDatePicker
            disabledDate={disabledDate}
            placeholder={getSelectPlaceHolder('date')}
            format='MM/DD/YYYY'
            style={{width: '100%'}}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={filterConfig.years_experience.label}
          name='years_experience'>
          <Select
            mode='multiple'
            placeholder={getSelectPlaceHolder('years')}
            options={filterConfig.years_experience.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Minimum Education' name='education_degree_level'>
          <Select
            options={filterConfig.education_degree_level.options}
            placeholder={getSelectPlaceHolder('degree')}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={filterConfig.skill_tags.label} name='skill_tags'>
          <SkillTagDropdown
            labelInValue={true}
            placeholder={getInputPlaceHolder('required skills')}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={filterConfig.visa_status.label} name='visa_status'>
          <Select
            mode='multiple'
            placeholder={getSelectPlaceHolder('status')}
            options={filterConfig.visa_status.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={filterConfig.travel_availability.label}
          name='travel_availability'>
          <Select
            options={filterConfig.travel_availability.options}
            placeholder={getSelectPlaceHolder('availability')}
            name='travel_availability'
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={filterConfig.type.label} name='type'>
          <Select
            placeholder={getSelectPlaceHolder('applicant type')}
            options={filterConfig.type.options}
          />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default FilterApplicants;
