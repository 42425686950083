import commonRoutes from './common';
import {supplier_AdministrationRoutes as administration} from './user-routes/administration';
import {supplier_ApplicantsRoutes as applicants} from './user-routes/applicants';
import {supplier_CandidateRoutes as candidates} from './user-routes/candidates';
import {supplier_ClientRoutes as clients} from './user-routes/clients';
import {supplier_DashboardRoutes as dashboard} from './user-routes/dashboard';
import {supplier_EngagementRoutes as engagements} from './user-routes/engagements';
import {supplier_ExpenseRoutes as expenses} from './user-routes/expenses';
import {supplier_FinancialRoutes as financials} from './user-routes/financials';
import {supplier_InquiryRoutes as inquiries} from './user-routes/inquiries';
import {supplier_ReportRoutes as reports} from './user-routes/reports';
import {supplier_RequisitionRoutes as requisitions} from './user-routes/requisitions';
import {supplier_ResourceRoutes as resources} from './user-routes/resources';
import {supplier_SubmissionRoutes as submissions} from './user-routes/submissions';
import {supplier_TimecardRoutes as timecards} from './user-routes/timecards';

const routes = [
  ...dashboard,
  ...clients,
  ...applicants,
  ...requisitions,
  ...inquiries,
  ...submissions,
  ...candidates,
  ...engagements,
  ...resources,
  ...timecards,
  ...expenses,
  ...financials,
  ...reports,
  ...administration,
  ...commonRoutes,
];

export default routes;
