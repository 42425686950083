import {Button, Space, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {WORK_TYPE_KEYS} from '../../../../constants/work-types';

const SubmitRequisition = () => {
  const navigate = useNavigate();
  const {currentStep, setIsPreview, finishSteps, isUpdated, stepData} =
    useStep();

  const isDirectHire = stepData.type === WORK_TYPE_KEYS.direct_hire;

  const step = isDirectHire ? 6 : 5;

  const onCancelCreate = () => {
    navigate(-1);
  };

  return (
    <div>
      <Typography.Paragraph>
        Take the final action to post the requisition
      </Typography.Paragraph>
      {currentStep === step && (
        <Space>
          <Button type='primary' onClick={finishSteps}>
            {isUpdated ? 'Update Requisition' : 'Post Requisition'}
          </Button>
          <Button type='primary' onClick={() => setIsPreview(true)} ghost>
            Preview
          </Button>
          <Button onClick={onCancelCreate}>Cancel</Button>
        </Space>
      )}
    </div>
  );
};

export default React.memo(SubmitRequisition);
