import AppCard from '@wieldy/components/AppCard';
import AppTable from '@wieldy/components/AppTable';
import {Button, Form, Typography} from 'antd';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';

import useStep from '../../../../../../../hooks/useStep';
import ResourceModal from '../../../../../../../modals/ResourceModal';
import {getColumns} from './columns';

const SelectedList = forwardRef((props, ref) => {
  const {candidates} = useSelector(({submission}) => submission);
  const {nextStep} = useStep();

  const data = candidates.map((el) => new ResourceModal(el));
  const initialValue = data.reduce((acc, value) => {
    acc[value.id] = value.billing_rate;
    return acc;
  }, {});
  const refInit = useRef(initialValue);

  useEffect(() => {
    if (!isEmpty(refInit.current)) {
      ref.current.setFieldsValue(refInit.current);
    }
  }, [ref]);

  const handleContinue = async () => {
    try {
      await ref.current.validateFields();

      nextStep();
    } catch (e) {
      console.log();
    }
  };

  return (
    <>
      <Typography.Paragraph>
        {candidates.length} candidates selected
      </Typography.Paragraph>
      <AppCard>
        <Form ref={ref}>
          <AppTable
            columns={getColumns(!props.isShowPreview)}
            dataSource={data}
            pagination={false}
          />
          {props.isShowPreview && (
            <Button type='primary mt-3' onClick={handleContinue}>
              Continue
            </Button>
          )}
        </Form>
      </AppCard>
    </>
  );
});

SelectedList.propTypes = {
  candidates: PropTypes.array,
  isShowPreview: PropTypes.bool,
};
SelectedList.displayName = 'SelectedList';

export default SelectedList;
