import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  RECRUITMENT_FEE_STRUCTURE,
  RECRUITMENT_FEE_STRUCTURE_KEYS,
} from '../../../constants';
import {formatBillingRateRage} from '../../../utils/helpers/DataFormatHelper';

const RecruitmentTerms = ({requisition}) => {
  const isFixedFee =
    requisition.recruitment_fee_structure ===
    RECRUITMENT_FEE_STRUCTURE_KEYS.fixed_fee;
  return (
    <div className='mb-5'>
      <Typography.Title level={4} className='mb-5'>
        Recruitment Terms
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Recruitment Fee Structure'>
          {RECRUITMENT_FEE_STRUCTURE[requisition.recruitment_fee_structure]}
        </Descriptions.Item>
        <Descriptions.Item label='Recruitment fee'>
          {isFixedFee
            ? formatBillingRateRage(requisition.recruitment_fee)
            : `${requisition.recruitment_fee} %`}
        </Descriptions.Item>
        <Descriptions.Item label='Guarantee Period'>
          {GUARANTEE_PERIOD[requisition.guarantee_period]}
        </Descriptions.Item>
        <Descriptions.Item label='Guarantee Period Action'>
          {GUARANTEE_PERIOD_ACTION[requisition.guarantee_period_action]}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

RecruitmentTerms.propTypes = {
  requisition: PropTypes.object,
};

export default React.memo(RecruitmentTerms);
