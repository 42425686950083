import AppCard from '@wieldy/components/AppCard';
import {Descriptions, Typography} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

import {
  APPLICANT_TYPE,
  EDUCATIONS,
  JOB_STATUSES,
  RESOURCE_AVAILABILITY,
  RESOURCE_AVAILABILITY_OVERTIME,
  RESOURCE_WILLING_TO_RELOCATE,
  RESOURCE_WORK_ARRANGEMENT,
  TIMEZONES,
  TRAVEL_AVAILABILITY,
  VISA_STATUSES,
} from '../../../../../../constants';
import {DURATION} from '../../../../../../constants/duration';
import Editor from '../../../../../../shared/components/Editor/Editor';
import {capitalizeFirstLetter} from '../../../../../../utils/helpers/Capitalize';
import {
  formatDate,
  getAddress,
  getPhoneNumber,
} from '../../../../../../utils/helpers/DataFormatHelper';
import {isJson} from '../../../../../../utils/helpers/isJson';

const SummaryDetails = () => {
  const {summary} = useSelector(({resource}) => resource);
  const {country, us_state, city} = summary;
  const location = getAddress({country, us_state, city});
  const TRAVEL_OPTIONS = {no: 'No', ...TRAVEL_AVAILABILITY};

  return (
    <AppCard style={{maxWidth: 990}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='First Name'>
          {summary.first_name}
        </Descriptions.Item>
        <Descriptions.Item label='Last Name'>
          {summary.last_name}
        </Descriptions.Item>
        <Descriptions.Item label='Title'>{summary.title}</Descriptions.Item>
        <Descriptions.Item label='Location'>{location}</Descriptions.Item>
        <Descriptions.Item label='Years of Work Experience'>
          {`${summary.years_experience} ${
            summary.years_experience
              ? summary.years_experience === 1
                ? 'year'
                : 'years'
              : ''
          }`}
        </Descriptions.Item>
        <Descriptions.Item label='Availability Status'>
          {RESOURCE_AVAILABILITY[summary.availability] ||
            capitalizeFirstLetter(summary.availability)}
        </Descriptions.Item>
        <Descriptions.Item label='Available From'>
          {formatDate(summary.available_on, 'MM-DD-YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label='Available To'>
          {formatDate(summary.available_to, 'MM-DD-YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label='Minimum Engagement'>
          {DURATION[summary.assignment_duration_min]}
        </Descriptions.Item>
        <Descriptions.Item label='Maximum Engagement'>
          {DURATION[summary.assignment_duration_max]}
        </Descriptions.Item>
        <Descriptions.Item label='Work Status'>
          {JOB_STATUSES[summary.work_status]}
        </Descriptions.Item>
        <Descriptions.Item label='Local Time Zone'>
          {TIMEZONES[summary.timezone]}
        </Descriptions.Item>
        <Descriptions.Item label='Available for Overtime'>
          {RESOURCE_AVAILABILITY_OVERTIME[summary.overtime_availability]}
        </Descriptions.Item>
        <Descriptions.Item label='Available to Travel'>
          {TRAVEL_OPTIONS[summary.travel_availability]}
        </Descriptions.Item>
        <Descriptions.Item label='Work Arrangement'>
          {RESOURCE_WORK_ARRANGEMENT[summary.work_arrangement]}
        </Descriptions.Item>
        <Descriptions.Item label='Willing to Relocate'>
          {RESOURCE_WILLING_TO_RELOCATE[summary.willing_to_relocate]}
        </Descriptions.Item>
        <Descriptions.Item label='Applicant Type'>
          {APPLICANT_TYPE[summary.type]}
        </Descriptions.Item>
        <Descriptions.Item label='Part Time Assignment'>
          {summary.part_time_assignment ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label='Education Degree Level'>
          {EDUCATIONS[summary.education_degree_level] || ''}
        </Descriptions.Item>
        <Descriptions.Item label='Visa Status'>
          {VISA_STATUSES[summary.visa_status]}
        </Descriptions.Item>
        <Descriptions.Item label='Email Address'>
          {summary.email}
        </Descriptions.Item>
        <Descriptions.Item label='Phone Number'>
          {getPhoneNumber(summary.phone_number)}
        </Descriptions.Item>
      </Descriptions>
      <div className={'mb-8'}>
        <Typography.Title level={4}>About</Typography.Title>
        <Editor
          readOnly
          initValue={
            summary.about
              ? isJson(summary.about)
                ? JSON.parse(summary.about)
                : [{type: 'paragraph', data: {text: summary.about}}]
              : []
          }
        />
        {/*<Typography.Paragraph>{summary.about}</Typography.Paragraph>*/}
      </div>
    </AppCard>
  );
};

export default SummaryDetails;
