import {UserOutlined} from '@ant-design/icons';
import {Avatar, Card, List, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiBuildingLine} from 'react-icons/ri';

const {Meta} = Card;

const AppUserProfile = ({
  avatarUrl,
  avatarProps,
  title,
  titleProps,
  items,
  renderTitle,
  children,
  className,
  initials,
  isCompany = true,
  useInitialsOnEmptyAvatar = false,
}) => {
  const showInitials = useInitialsOnEmptyAvatar && !avatarUrl;
  return (
    <Meta
      avatar={
        showInitials ? (
          <Avatar
            size={60}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
            }}>
            {initials}
          </Avatar>
        ) : (
          <Avatar
            src={avatarUrl}
            size={60}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            {...avatarProps}
            icon={
              isCompany ? (
                <RiBuildingLine fontSize={40} />
              ) : (
                <UserOutlined fontSize={30} />
              )
            }
          />
        )
      }
      title={
        renderTitle ? (
          renderTitle
        ) : (
          <Typography.Link {...titleProps}>{title}</Typography.Link>
        )
      }
      className={`card-meta-root ${className ? className : ''}`}
      description={
        <>
          {items && items.length > 0 && (
            <List
              className='list-item-borderless'
              itemLayout='horizontal'
              dataSource={items}
              renderItem={(item) => (
                <List.Item className='py-0 hidden'>
                  <List.Item.Meta
                    style={{
                      margin: 0,
                    }}
                    className='align-items-center'
                    avatar={item.icon}
                    title={item.content}
                  />
                </List.Item>
              )}
            />
          )}
          {children}
        </>
      }
    />
  );
};

AppUserProfile.propTypes = {
  avatarUrl: PropTypes.oneOf([PropTypes.string, null]),
  avatarProps: PropTypes.object,
  useInitialsOnEmptyAvatar: PropTypes.bool,
  title: PropTypes.node,
  titleProps: PropTypes.object,
  items: PropTypes.array,
  children: PropTypes.node,
  renderTitle: PropTypes.node,
  className: PropTypes.string,
  isCompany: PropTypes.bool,
  initials: PropTypes.oneOf([PropTypes.string, null]),
};

AppUserProfile.defaultProps = {
  items: [],
};

export default AppUserProfile;
