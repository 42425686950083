import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {WORK_TYPE_KEYS} from '../../../constants/work-types';
import CompensationCandidateCriteria from './CompensationCandidateCriteria';
import JobDescription from './JobDescription';
import RecruitmentTerms from './RecruitmentTerms';
import SkillsResponsibilities from './SkillsResponsibilities';
import WorksiteTimings from './WorksiteTimings';

const RequisitionContentCard = ({children, requisition}) => {
  const isDirectHire = requisition.type === WORK_TYPE_KEYS.direct_hire;
  return (
    <AppGridContainer>
      <Col sm={24}>
        <AppCard
          title='Requisition'
          headStyle={{borderBottom: 0}}
          bodyStyle={{paddingTop: 8}}>
          <JobDescription requisition={requisition} />
          <SkillsResponsibilities requisition={requisition} />
          <WorksiteTimings requisition={requisition} />
          <CompensationCandidateCriteria requisition={requisition} />
          {isDirectHire && <RecruitmentTerms requisition={requisition} />}
          {children}
        </AppCard>
      </Col>
    </AppGridContainer>
  );
};

RequisitionContentCard.propTypes = {
  requisition: PropTypes.object,
  children: PropTypes.node,
};

export default RequisitionContentCard;
