import AppTag from '@wieldy/components/AppTag';
import {Col, Descriptions, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {WORK_TYPE_KEYS} from '../../../constants/work-types';

const WorksiteTimings = ({requisition}) => {
  const isDirectHire = requisition.type === WORK_TYPE_KEYS.direct_hire;
  return (
    <div className='mb-5'>
      <Typography.Title level={4} className='mb-5'>
        Worksite and Timeline
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Worksite Type'>
          {requisition.jobWorkSite}
        </Descriptions.Item>
        <Descriptions.Item label='Worksite Location(s)'>
          <Row wrap='wrap' gutter={[5, 5]}>
            {Array.isArray(requisition?.jobCountry) ? (
              requisition?.jobCountry.map((country) => (
                <Col key={country}>
                  <AppTag
                    key={country}
                    className={'border-none'}
                    shape={'square'}
                    label={country}
                  />
                </Col>
              ))
            ) : (
              <AppTag
                className={'border-none'}
                shape={'square'}
                label={requisition?.jobCountry || 'Any country'}
              />
            )}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Travel Required'>
          {requisition.travelRequirements}
        </Descriptions.Item>
        {!isDirectHire && (
          <Descriptions.Item label='Duration'>
            {requisition.jobDuration || 'TBD'}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={isDirectHire ? 'Job Start Date' : 'Start Date'}>
          {requisition.startDate}
        </Descriptions.Item>
        {!isDirectHire && (
          <Descriptions.Item label='End Date'>
            {requisition.endDate}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};

WorksiteTimings.propTypes = {
  requisition: PropTypes.object,
};

export default React.memo(WorksiteTimings);
