import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {getYesOrNo} from '../../../utils/helpers/DataFormatHelper';

const BillingRates = ({resource}) => {
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-3'}>
        Billing Rates
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Billing Rate'>
          {resource.billingRate} {resource.billingRate === 'N/A' ? '' : 'USD'}
        </Descriptions.Item>
        <Descriptions.Item label='Billing Rate Period'>
          {resource.billing_rate_period}
        </Descriptions.Item>
        <Descriptions.Item label='Open to Rate Negotiation'>
          {getYesOrNo(resource.billing_rate_negotiations)}
        </Descriptions.Item>
        <Descriptions.Item label='Open to Resource Buyout'>
          {getYesOrNo(resource.billing_rate_buy_out)}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

BillingRates.propTypes = {
  resource: PropTypes.object,
};

export default BillingRates;
