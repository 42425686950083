import {DownloadOutlined} from '@ant-design/icons';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag/AppTag';
import AppUserProfile from '@wieldy/components/AppUserProfile';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button, Divider, notification, Tooltip, Typography, Upload} from 'antd';
import axios from 'app/config/axios';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUpdateAvatarUser} from 'app/services/clientService';
import {useGetBlob, useUploadImageToAws} from 'app/services/globalService';
import {
  useAddBookmarkToResource,
  useDownloadResource,
  useUpdateResource,
} from 'app/services/resourceService';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import NotesBtnIcon from 'assets/icons/NotesBtn';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MdOutlineAddAPhoto} from 'react-icons/md';
import {
  RiMapPinLine,
  RiProfileLine,
  RiStarLine,
  RiStarSFill,
} from 'react-icons/ri';
import {useMutation} from 'react-query';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {downloadBlob} from '../../../pages/common/Terms/components/utils/downloadBlob';
import {
  useDownloadApplicant,
  useUpdateApplicant,
} from '../../../services/applicantService';
import {useDownloadCandidate} from '../../../services/candidateService';
import {
  setInquirySuppliers,
  setInquiryTargetResource,
} from '../../../store/features/inquiry/inquirySlice';
import {fileNameSchema} from '../../../utils/helpers/preventNegativeNumber';
import {getBase64, getUploadUrl} from '../../components/AvatarUpload/constants';
import DownloadResume from '../DownloadResume';

const ResourcePageContainer = ({
  resource,
  tabs,
  children,
  isNotes,
  handleShowNotes,
  jobTitle = '',
  isCandidate = false,
  isApplicant = false,
  hideNotesButton = false,
  supplier,
}) => {
  const {isClient, isSupplier} = useAccountType();
  const name = getUserName(resource?.name, 'RES-', 'Resource');
  const isPartialProfile = supplier?.hid === supplier?.name;

  const updateApplicant = useUpdateApplicant(resource?.id);
  const mutateAvatar = useUpdateResource(resource?.id);
  const uploadMutation = useUploadImageToAws();
  const mutation = useAddBookmarkToResource(true);
  const userAvatarMutation = useUpdateAvatarUser(resource?.resource?.user?.id);
  const downloadData = (
    isApplicant
      ? useDownloadApplicant
      : isCandidate
      ? useDownloadCandidate
      : useDownloadResource
  )();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const dispatch = useDispatch();

  const {data: blob} = useGetBlob(resource?.resource?.temporary_resume_key);

  const downloadFileName = isPartialProfile
    ? `Sourcer__${resource.firstName}-${resource.lastName.replace(
        '.',
        '',
      )}__Supplier-${fileNameSchema(supplier?.hid, false)}.pdf`
    : `Sourcer__${resource.firstName}-${resource.lastName.replace(
        '.',
        '',
      )}__${fileNameSchema(supplier?.name, true)}.pdf`;

  const items = [
    {
      icon: <RiMapPinLine fontSize={16} className='text-secondary' />,
      content: resource?.location,
    },
    {
      icon: <RiProfileLine fontSize={16} />,
      content: resource?.title,
    },
  ];

  const mutationAvatar = useMutation(async (payload) => {
    const uploadUrl = getUploadUrl('profile', resource?.supplier);
    if (!uploadUrl) {
      throw new Error('Missing variant or id');
    }
    const {data} = await axios.post(uploadUrl, payload);
    return data;
  });

  const handleUpdateAvatar = useCallback(
    (uploadAvatar) => {
      const update = isApplicant ? updateApplicant.mutate : mutateAvatar.mutate;

      update(
        {
          avatar_url: uploadAvatar.url,
        },
        {
          onSuccess: () => {
            notification.success({
              message: `${
                isApplicant ? 'Applicant' : 'Resource'
              } avatar updated successfully`,
            });

            if (resource?.resource?.user) {
              userAvatarMutation.mutate(
                {
                  avatar_url: uploadAvatar.url,
                },
                {
                  onError: (error) => {
                    notification.error({
                      message: error.message,
                    });
                  },
                },
              );
            }
          },
          onError: () => {
            notification.error({
              message:
                'Timeout to upload a logo expired, please try again to upload file',
            });
          },
        },
      );
    },
    [
      mutateAvatar,
      isApplicant,
      updateApplicant.mutate,
      resource.resource.user,
      userAvatarMutation,
    ],
  );

  const uploadAvatarToAws = useCallback(
    (uploadAvatar) => {
      if (uploadAvatar) {
        uploadMutation.mutate(
          {
            uploadUrl: uploadAvatar.upload_url,
            file: uploadAvatar.file,
          },
          {
            onSuccess: () => {
              setAvatarUrl(uploadAvatar.preview);
              handleUpdateAvatar(uploadAvatar);
            },
            onError: (error) => {
              notification.error({
                message: error?.message,
              });
              setAvatarUrl(undefined);
            },
          },
        );
      }
    },
    [handleUpdateAvatar, uploadMutation],
  );

  const handleDownload = useCallback(() => {
    downloadData.mutate(
      {
        id: isCandidate ? resource.id : resource.resource?.id,
      },
      {
        onSuccess: (response) => {
          downloadBlob(response.data, downloadFileName);
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [
    downloadData,
    downloadFileName,
    isCandidate,
    resource.id,
    resource.resource?.id,
  ]);

  const handleBeforeUpload = useCallback(
    (file) => {
      mutationAvatar.mutate(
        {
          content_type: file.type,
          content_length: file.size,
        },
        {
          onSuccess: async (signedUrl) => {
            const preview = await getBase64(file);

            uploadAvatarToAws({
              ...signedUrl,
              preview,
              file: file,
            });
          },
          onError: (error) => {
            if (error?.response?.data) {
              const errorCode = error?.response?.data?.code;
              if (errorCode === 'validation_failed') {
                notification.error({
                  message: 'Invalid file or size. Please select image file.',
                });
                return;
              }
            }
            notification.error({message: error.message});
          },
        },
      );

      return false;
    },
    [mutationAvatar, uploadAvatarToAws],
  );
  const navigate = useNavigate();
  const handleSetInquiryResource = () => {
    dispatch(setInquirySuppliers([supplier]));
    dispatch(setInquiryTargetResource(resource.id));
    navigate('/inquiries/new-inquiry');
  };

  const handleOnBookmark = React.useCallback(() => {
    mutation.mutate({
      resource: resource?.id,
      isBookmarked: resource?.bookmarked,
    });
  }, [mutation, resource?.id, resource?.bookmarked]);

  useEffect(() => {
    if (resource) {
      setAvatarUrl(
        resource?.avatar ||
          resource?.resource?.user?.avatar_url ||
          resource?.resource?.avatar_url,
      );
    }
  }, [resource]);

  const category = useMemo(() => {
    if (isApplicant) return 'Applicant';
    if (isCandidate) return 'Candidate';

    return 'Resource';
  }, [isApplicant, isCandidate]);

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          backHeaderActions={
            isClient &&
            !isCandidate && (
              <Button
                type='text'
                shape='circle'
                loading={mutation.isLoading}
                className={clsx({bookmarked: resource?.bookmarked ?? false})}
                onClick={handleOnBookmark}
                icon={
                  resource?.bookmarked ? (
                    <RiStarSFill className='text-xxl' />
                  ) : (
                    <RiStarLine className='text-xxl' />
                  )
                }
              />
            )
          }
          header='inner'
          category={category}
          extra={
            <>
              {!isCandidate && isClient && (
                <>
                  <Button
                    type='primary'
                    shape='round'
                    className='post-header-button items-center d-none lg:flex w-full'
                    onClick={handleSetInquiryResource}>
                    Inquire
                  </Button>
                  <Divider type='vertical' style={{height: 28}} />
                </>
              )}
              <DownloadResume
                blob={blob}
                name={`resume__${resource.firstName}-${resource.lastName}`}
                isShow={!!resource?.resource?.temporary_resume_key}
              />
              <Tooltip
                overlayStyle={{fontSize: '12px'}}
                title={'Download Profile'}>
                <Button
                  type='primary'
                  shape='circle'
                  className='post-header-button items-center d-none lg:flex w-full'
                  loading={downloadData.isLoading}
                  onClick={handleDownload}
                  icon={<DownloadOutlined style={{fontSize: '18px'}} />}
                />
              </Tooltip>
              {!isNotes && !hideNotesButton && (
                <Divider type='vertical' style={{height: 28}} />
              )}
              {!isNotes && !hideNotesButton && (
                <Tooltip
                  overlayStyle={{fontSize: '12px'}}
                  title={'Add Internal Note'}>
                  <Button
                    type='primary'
                    shape='circle'
                    onClick={handleShowNotes}
                    icon={<NotesBtnIcon />}
                  />
                </Tooltip>
              )}
            </>
          }
          tabs={<AppDynamicTabs tabsData={tabs} scroll={true} />}>
          <div className='page-header-info'>
            <AppUserProfile
              isCompany={false}
              className='page-header-info-content d-flex flex-fill align-items-center relative'
              avatarUrl={avatarUrl}
              avatarProps={{shape: 'circle'}}
              items={items}
              renderTitle={
                <Typography.Title
                  level={3}
                  className={'d-flex align-items-center mb-0'}>
                  {name}
                  {jobTitle && (
                    <>
                      <Divider type='vertical' style={{height: 20}} />
                      <Typography.Text type='secondary'>
                        {jobTitle}
                      </Typography.Text>
                    </>
                  )}
                  <AppTag
                    color={resource?.availabilityStatusColor}
                    shape={'circle'}
                    label={resource?.availabilityName}
                    className={'tracking-normal ml-2'}
                  />
                </Typography.Title>
              }>
              {isSupplier && !isCandidate && (
                <Upload
                  showUploadList={false}
                  accept={'image/png, image/jpeg'}
                  beforeUpload={handleBeforeUpload}
                  className='page-header-info-upload-avatar'>
                  <Button
                    loading={mutation.isLoading}
                    icon={
                      <MdOutlineAddAPhoto
                        color='#bcbaba'
                        height={30}
                        width={30}
                      />
                    }
                    type='link'
                  />
                </Upload>
              )}
            </AppUserProfile>
            <div className={'page-header-info-extra'}>
              <div className='list-info'>
                <div className='list-info-item'>
                  <Typography.Text className='info-count'>
                    {resource?.years_experience}
                  </Typography.Text>
                  <Typography.Text type={'secondary'}>
                    Years Experience
                  </Typography.Text>
                </div>
                {!resource?.type && (
                  <div className='list-info-item'>
                    <Typography.Text className='info-count'>
                      {resource?.completed_engagements}
                    </Typography.Text>
                    <Typography.Text type={'secondary'}>
                      Past Assignments
                    </Typography.Text>
                  </div>
                )}

                <div className='list-info-item'>
                  <Typography.Text className='info-count'>
                    {resource?.billingRate}
                  </Typography.Text>
                  <Typography.Text type={'secondary'}>
                    {resource?.billing_rate_period} Billing Rate
                  </Typography.Text>
                </div>
              </div>
            </div>
          </div>
        </AppPageHeader>
      }>
      {children}
    </AppPageContainer>
  );
};

ResourcePageContainer.propTypes = {
  resource: PropTypes.object,
  tabs: PropTypes.array,
  extra: PropTypes.node,
  children: PropTypes.node,
  isNotes: PropTypes.bool,
  handleShowNotes: PropTypes.func,
  isCandidate: PropTypes.bool,
  isApplicant: PropTypes.bool,
  jobTitle: PropTypes.string,
  hideNotesButton: PropTypes.bool,
  supplier: PropTypes.object,
};
export default ResourcePageContainer;
