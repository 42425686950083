import PropTypes from 'prop-types';
import React from 'react';

const ItemInfo = ({label, content}) => {
  return (
    <tr>
      <td width='30%' className={'text-secondary'}>
        {label}
      </td>
      <td>{content}</td>
    </tr>
  );
};

ItemInfo.propTypes = {
  label: PropTypes.string,
  content: PropTypes.node,
};

const EngagementCardContent = ({engagement}) => {
  return (
    <table className='table' style={{margin: '0 -8px 24px'}}>
      <tbody>
        <ItemInfo label='Job Status' content={engagement?.job_status} />
        <ItemInfo label='Duration' content={engagement?.job_duration} />
        <ItemInfo label='Start Date' content={engagement?.start_date} />
        <ItemInfo label='End Date' content={engagement?.end_date} />
        <ItemInfo label='Worksite Type' content={engagement?.job_worksite} />
        <ItemInfo
          label='Location'
          content={engagement?.job_worksite_location}
        />
      </tbody>
    </table>
  );
};

EngagementCardContent.propTypes = {
  engagement: PropTypes.object,
};

export default EngagementCardContent;
