import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {
  COMPANY_SIZE,
  DIVERSITY_STATUSES,
  ENGAGEMENT_ALL_STATUSES,
  INDUSTRIES,
  SERVICE_FOCUSES,
} from 'app/constants';
import Editor from 'app/shared/components/Editor/Editor';
import PropTypes from 'prop-types';
import React from 'react';

import {supplierTypesNames} from '../../../../../../constants/supplier-types';
import MultipleValuePreview from '../../../../../../shared/components/MultipleValuePreview';

const CompanyInfoView = ({details}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Company Name</Typography.Title>
        <Typography.Paragraph>{details?.name}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Supplier Type</Typography.Title>
        <Typography.Paragraph>
          <MultipleValuePreview
            type={details?.type}
            source={supplierTypesNames}
          />
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Service Focus</Typography.Title>
        <Typography.Paragraph>
          {SERVICE_FOCUSES[details?.service_focus]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Company Size</Typography.Title>
        <Typography.Paragraph>
          {COMPANY_SIZE[details?.company_size]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Engagement Status</Typography.Title>
        <Typography.Paragraph>
          {ENGAGEMENT_ALL_STATUSES[details?.engagement_status]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Website</Typography.Title>
        <Typography.Paragraph>{details?.website}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Linkedin Company Page</Typography.Title>
        <Typography.Paragraph>{details?.linkedin}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Diversity Status</Typography.Title>
        <Typography.Paragraph>
          {!!details?.diversity_status?.length &&
            details?.diversity_status
              .map((ds) => DIVERSITY_STATUSES[ds])
              .join(', ')}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Industry Focus</Typography.Title>
        <Typography.Paragraph>
          {!!details?.industry_focuses?.length &&
            details?.industry_focuses.map((sf) => INDUSTRIES[sf]).join(', ')}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>About</Typography.Title>
        <Editor readOnly initValue={details?.about} />
      </div>
    </AppCard>
  );
};

CompanyInfoView.propTypes = {
  details: PropTypes.object,
};

export default CompanyInfoView;
