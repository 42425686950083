import {Button, Space, Typography} from 'antd';
import {useStatus} from 'app/hooks/useStatus';
import useStep from 'app/hooks/useStep';
import {
  useCreateSubmission,
  useUpdateSubmission,
} from 'app/services/submissionService';
import {resetInquiryState} from 'app/store/features/inquiry/inquirySlice';
import {resetSubmissionState} from 'app/store/features/submission/inquirySlice';
import React, {forwardRef, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

const PostSubmission = forwardRef((props, ref) => {
  const {submissionId, submissionStatus} = useParams();
  const {currentStep} = useStep();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {isResponded} = useStatus(submissionStatus);

  const {candidates, requisition, accompanying_message, isEditMode} =
    useSelector(({submission}) => submission);
  const {mutate, isLoading: createIsLoading} = useCreateSubmission();

  const {mutate: editMutate, isLoading: editIsLoading} =
    useUpdateSubmission(submissionId);
  const isLoading = isEditMode ? editIsLoading : createIsLoading;

  const onCancelCreate = () => {
    dispatch(resetInquiryState());
    if (isResponded) {
      navigate('/submissions/in_progress');
    } else {
      navigate('/submissions/new');
    }
  };

  const onMutate = useMemo(
    () => (isEditMode ? editMutate : mutate),
    [editMutate, isEditMode, mutate],
  );

  const onSubmit = async () => {
    try {
      const values = await ref.current.validateFields();

      const payload = {
        candidates: candidates.map(
          ({id, available_on, billing_rate_period, type}) => ({
            ...(type ? {applicant: id} : {resource: id}),
            billing_rate: values[id],
            available_on: available_on || undefined,
            ...(billing_rate_period && {billing_rate_period}),
          }),
        ),
        accompanying_message,
      };

      if (!isEditMode) {
        payload.requisition = requisition.id;
      }

      onMutate(payload, {
        onSuccess: () => {
          dispatch(resetSubmissionState());
          if (isResponded) {
            navigate('/submissions/in_progress');
          } else {
            navigate('/submissions/new');
          }
        },
      });
    } catch (e) {
      console.log(e, '---Error');
    }
  };

  return (
    <div>
      <Typography.Paragraph>Post the submission</Typography.Paragraph>
      {currentStep === 4 && (
        <Space>
          <Button type='primary' onClick={onSubmit} loading={isLoading}>
            {isEditMode ? 'Add Candidates' : 'Submit Submission'}
          </Button>
          <Button onClick={onCancelCreate} disabled={isLoading}>
            Cancel
          </Button>
        </Space>
      )}
    </div>
  );
});

export default PostSubmission;
