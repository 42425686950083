import ResourceModal from './ResourceModal';

class ApplicantModal extends ResourceModal {
  constructor(data = {}) {
    super(data);
    this.type = data?.type || 'temporary';
  }
}

export default ApplicantModal;
