import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import Editor from 'app/shared/components/Editor/Editor';
import {isValidURL} from 'app/utils/helpers/isValidUrl';
import PropTypes from 'prop-types';
import React from 'react';

import {supplierTypesOptions} from '../../../../../../constants/supplier-types';
import formConfig from './config';

const CompanyInfoForm = ({onContinue, details, setState}) => {
  const handleOnSubmit = (formData) => {
    setState({company_info: formData});
    if (onContinue) onContinue();
  };

  const handleChange = (aboutData) => {
    setState((prev) => ({
      company_info: {
        ...prev,
        about: aboutData,
      },
    }));
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          autoComplete='off'
          onFinish={handleOnSubmit}
          initialValues={details}>
          <AppFormBody>
            <Form.Item
              name='name'
              label='Company Name'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Company name'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('company name')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='type'
              label='Supplier Type'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Supplier type'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('supplier types')}
                options={supplierTypesOptions}
                allowClear
                style={{width: '100%', maxWidth: '280px'}}
                mode='multiple'
              />
            </Form.Item>

            <Form.Item
              name='service_focus'
              label='Service Focus'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Service Focus'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder('service focus')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={formConfig.service_focus.options}
              />
            </Form.Item>

            <Form.Item
              name='company_size'
              label='Company Size'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Company size'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder('company size')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={formConfig.company_size.options}
              />
            </Form.Item>

            <Form.Item
              name='engagement_status'
              label='Engagement Status'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Engagement status'),
                },
              ]}>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder('status')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={formConfig.engagement_status.options}
              />
            </Form.Item>

            <Form.Item
              name='website'
              label='Website'
              rules={[
                {
                  validator: isValidURL,
                },
                {
                  required: true,
                  message: getRequiredMessage('Website'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('website')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item
              name='linkedin'
              label='Linkedin Company Page'
              rules={[
                {
                  validator: isValidURL,
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('linkedin company page')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
              />
            </Form.Item>

            <Form.Item name='diversity_status' label='Diversity Status'>
              <AppSelect
                showSearch
                mode='tags'
                placeholder={getSelectPlaceHolder('diversity status')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                options={formConfig.diversity_focus.options}
              />
            </Form.Item>

            <Form.Item name='industry_focuses' label='Industry Focus'>
              <AppSelect
                placeholder={getSelectPlaceHolder('industry focus')}
                style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}
                mode='tags'
                options={formConfig.industries.options}
              />
            </Form.Item>

            <Form.Item name='about' label='About'>
              <Editor
                key='about'
                isCompanyProfile
                placeholder={getInputPlaceHolder('company description')}
                onChange={handleChange}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

CompanyInfoForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default React.memo(CompanyInfoForm);
