import AppCard from '@wieldy/components/AppCard';
import {Descriptions, Typography} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

import {BILLING_RATE_PERIODS} from '../../../../../../constants';
import {
  formatBillingRateRage,
  getYesOrNo,
} from '../../../../../../utils/helpers/DataFormatHelper';

const BillingAndEnvironmentDetails = () => {
  const {billing} = useSelector(({resource}) => resource);
  const {billing_rate, billing_rate_min, billing_rate_max} = billing;
  const billingRate = formatBillingRateRage(
    billing_rate,
    billing_rate_min,
    billing_rate_max,
  );

  return (
    <AppCard style={{maxWidth: 990}}>
      <Typography.Title level={4}>Billing rates</Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Billing Rate'>
          {billingRate}
        </Descriptions.Item>
        <Descriptions.Item label='Billing Rate Period'>
          {BILLING_RATE_PERIODS[billing.billing_rate_period]}
        </Descriptions.Item>
        <Descriptions.Item label='Open to Rate Negotiation'>
          {getYesOrNo(billing.billing_rate_negotiations)}
        </Descriptions.Item>
        <Descriptions.Item label='Open to Resource Buyout'>
          {getYesOrNo(billing.billing_rate_buy_out)}
        </Descriptions.Item>
      </Descriptions>
    </AppCard>
  );
};

export default BillingAndEnvironmentDetails;
