import './SelectedList.style.less';

import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import {getInputPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Form, Typography} from 'antd';
import {preventNegativeNumber} from 'app/utils/helpers/preventNegativeNumber';
import React from 'react';

const validate = (_, value, callback) => {
  const numberRegex = /^\d*(.\d{0,2})?$/;
  if (value && !numberRegex.test(value)) {
    callback('Billing Rate is not a valid');
  } else {
    callback();
  }
};
export const getColumns = (isRateReadOnly) => {
  return [
    {
      title: 'Name/Location',
      dataIndex: 'id',
      key: 'id',
      render: (_, item) => {
        return (
          <>
            <Typography.Title level={5} className={'text-sm mb-0'}>
              {item.name}
            </Typography.Title>
            <Typography.Text>{item.location}</Typography.Text>
          </>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
    },
    {
      title: 'Resource/Applicant Billing Rate',
      dataIndex: 'billingRateLabel',
      key: 'billingRateLabel',
      render: (billingRateLabel) => {
        return <Typography.Text>{billingRateLabel}</Typography.Text>;
      },
    },
    {
      title: 'Candidate Billing Rate',
      key: 'candidatesBillingRate',
      render: (_, {id}) => (
        <Form.Item
          name={id}
          rules={[
            {required: true, message: ''},
            {validator: validate, message: ''},
          ]}
          className='mb-0'>
          <AppInputNumber
            placeholder={getInputPlaceHolder('rate')}
            prefix='$'
            type='number'
            pattern='[0-9]+([\.,][0-9]+)?'
            step='any'
            disabled={isRateReadOnly}
            className='app-input-number-wrapper'
            min={0}
            style={{width: 100}}
            controls={false}
            stringMode={true}
            onKeyPress={preventNegativeNumber}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Resource/Applicant Status',
      dataIndex: 'availabilityName',
      key: 'statusName',
      render: (status) => <Typography.Text>{status}</Typography.Text>,
    },
  ];
};
