import AppLabel from '@wieldy/components/AppLabel';
import {Descriptions, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import ApplicantType from '../../../../constants/applicant-type';
import {
  getPhoneNumber,
  getYesOrNo,
} from '../../../../utils/helpers/DataFormatHelper';

const Overview = ({resource}) => {
  return (
    <Descriptions
      colon={false}
      labelStyle={{width: '50%'}}
      className='description-root mb-6'
      column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
      <Descriptions.Item label='Available From'>
        {resource.available_on}
      </Descriptions.Item>
      <Descriptions.Item label='Available To'>
        {resource.available_to}
      </Descriptions.Item>
      <Descriptions.Item label='Minimum Engagement'>
        {resource.assignment_duration_min}
      </Descriptions.Item>
      <Descriptions.Item label='Maximum Engagement'>
        {resource.assignment_duration_max}
      </Descriptions.Item>
      <Descriptions.Item label='Work Status'>
        {resource.work_status}
      </Descriptions.Item>
      <Descriptions.Item label='Local Time Zone'>
        {resource.timezone}
      </Descriptions.Item>
      <Descriptions.Item label='Available for Overtime'>
        {resource.overtime_availability}
      </Descriptions.Item>
      <Descriptions.Item label='Available to Travel'>
        {resource.travel_availability}
      </Descriptions.Item>
      <Descriptions.Item label='Willing to Relocate'>
        {resource.willing_to_relocate}
      </Descriptions.Item>
      <Descriptions.Item label='Work Arrangement'>
        {resource.work_arrangement}
      </Descriptions.Item>
      {resource.type ? (
        <Descriptions.Item label='Applicant Type'>
          {ApplicantType[resource.type]}
        </Descriptions.Item>
      ) : (
        <Descriptions.Item label='Employed Since'>
          {resource.employed_since}
        </Descriptions.Item>
      )}

      <Descriptions.Item label='Part Time Assignment'>
        {getYesOrNo(resource.part_time_assignment)}
      </Descriptions.Item>
      <Descriptions.Item label='Education Degree Level'>
        {resource.education_degree_level}
      </Descriptions.Item>
      <Descriptions.Item label='Visa Status'>
        <Row>
          {resource.visa_status && <AppLabel label={resource.visa_status} />}
        </Row>
      </Descriptions.Item>
      {resource.type && (
        <Descriptions.Item label='Email Address'>
          {resource.email}
        </Descriptions.Item>
      )}
      {resource.phone_number && (
        <Descriptions.Item label='Phone Number'>
          {getPhoneNumber(resource.phone_number)}
        </Descriptions.Item>
      )}
      <Descriptions.Item label='Candidate ID'>{resource.hid}</Descriptions.Item>
      <Descriptions.Item label='Updated'>
        {resource.candidate_updated_at || resource.updated_at}
      </Descriptions.Item>
      <Descriptions.Item label=''>{}</Descriptions.Item>
    </Descriptions>
  );
};

Overview.propTypes = {
  resource: PropTypes.object,
};

export default React.memo(Overview);
