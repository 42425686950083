export default {
  contract: 'Contract',
  direct_hire: 'Direct hire',
};

export const WORK_TYPE_KEYS = {
  contract: 'contract',
  direct_hire: 'direct_hire',
};

export const CONSTRACT_OPTIONS_KEYS = {
  talent_vendors: 'talent_vendors',
  staffing_agencies: 'staffing_agencies',
};

export const CONTRACT_OPTIONS = {
  talent_vendors: 'Talent Vendors',
  staffing_agencies: 'Staffing Agencies',
};

export const DIRECT_HIRE_OPTIONS = {
  recruitment_agencies: 'Recruitment agencies',
  direct_sourcing: 'Direct sourcing',
};

export const ACCEPT_CANDIDATES_FROM_MAP = {
  contract: CONTRACT_OPTIONS,
  direct_hire: DIRECT_HIRE_OPTIONS,
};
