import AppLoader from '@wieldy/components/AppLoader';
import {useNotes} from 'app/hooks/useNotes';
import ResourceModal from 'app/modals/ResourceModal';
import SupplierModal from 'app/modals/SupplierModal';
import {ResourcePageContainer, ResourceProvider} from 'app/shared/resource';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {useGetApplicant} from '../../../../services/applicantService';
import {getPrimaryTabs} from './constants';
import MainContent from './MainContent';

const ViewApplicant = () => {
  const {id} = useParams();

  const {isLoading, data} = useGetApplicant(id, {
    include: [
      'skill_tags',
      'supplier_active_clients',
      'supplier_completed_engagements',
      'supplier_available_resources',
      'supplier_relationship_status',
      'supplier_inquiries',
      'supplier_submissions',
      'supplier_engagements',
    ],
    expand: ['supplier'],
  });

  const resourceModal = useMemo(() => new ResourceModal(data), [data]);

  const supplier = useMemo(() => new SupplierModal(data?.supplier), [data]);

  const notes = useNotes('applicant_notes', 'applicant', id);

  const tabView = useMemo(
    () => ({
      isRating: !!resourceModal.rating_total && !!resourceModal.rating_count,
      isSkills: !!resourceModal.skills.length,
      isEducation: !!resourceModal.educations.length,
      isCourses: !!resourceModal.courses.length,
      isCertifications: !!resourceModal.certifications.length,
      isLanguages: !!resourceModal.languages.length,
      isHistory: !!resourceModal.employment_history.length,
      isNotes: notes && notes?.isNotes,
    }),
    [resourceModal, notes],
  );

  const primaryTabs = getPrimaryTabs(tabView);
  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <ResourceProvider>
      <ResourcePageContainer
        tabs={primaryTabs}
        supplier={supplier}
        resource={resourceModal}
        isNotes={tabView.isNotes}
        hideNotesButton={!notes}
        isApplicant
        handleShowNotes={notes?.handleShowNotes}>
        <MainContent
          supplier={supplier}
          resource={resourceModal}
          tabView={tabView}
          isShowNotes={notes?.isShowNotes}
          notesTotal={notes?.total}
          notesResponse={notes?.response}
        />
      </ResourcePageContainer>
    </ResourceProvider>
  );
};

export default ViewApplicant;
